




import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class Playlists extends Vue {

}
